import Echo from 'laravel-echo'
import { isEmpty } from 'lodash'
import Pusher from 'pusher-js'
import { reactive } from 'vue'
import { useAuthStore } from '~/store/auth'

export default defineNuxtPlugin((nuxtApp) => {
  if (!process.client) return

  const echoWrapper = reactive({ instance: null })

  const caseRef = ref(null)

  const initializeEcho = (authStore) => {
    const {
      public: { reverb, environment },
    } = useRuntimeConfig()

    const options = {
      broadcaster: 'reverb',
      key: reverb.app_key,
      wsHost: reverb.host,
      wsPort: reverb.port,
      wssPort: reverb.port,
      forceTLS: (reverb.scheme ?? 'https') === 'https',
      enabledTransports: ['ws', 'wss'],
      authEndpoint: reverb.auth_endpoint,
      auth: {
        withCredentials: true,
        headers: {
          Authorization: authStore.isAuthenticated ? authStore.authorizationHeader : undefined,
        },
      },
    }

    if (isEmpty(options.key)) {
      return null
    }

    if (['development', 'local', 'staging'].includes(environment)) {
      Pusher.logToConsole = true
    }

    return new Echo(options)
  }

  nuxtApp.hook('app:mounted', () => {
    const authStore = useAuthStore()

    echoWrapper.instance = initializeEcho(authStore)

    nuxtApp.provide('Echo', echoWrapper)

    authStore.$subscribe((mutation, state) => {
      if (state === null || state.client?.active_case !== caseRef.value) {
        if (echoWrapper.instance) {
          echoWrapper.instance.disconnect()
        }

        echoWrapper.instance = initializeEcho(authStore)

        caseRef.value = state.client?.active_case
      }
    })
  })
})
